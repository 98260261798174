<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="称号名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入称号名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="是否上架" prop="isEnable">
                <a-select placeholder="请选择上下架状态" style="width: 100%" v-model="queryParam.isEnable" allowClear>
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="称号类型" prop="type">
                <a-select placeholder="请选择称号类型" style="width: 100%" v-model="queryParam.type" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TitleTypeEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <!--              <a-col :md="8" :sm="24">
                              <a-form-item label="级别数,一共多少级别" prop="level">
                                <a-input v-model="queryParam.level" placeholder="请输入级别数,一共多少级别" allow-clear/>
                              </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24">
                              <a-form-item label="称号图片" prop="img">
                                <a-input v-model="queryParam.img" placeholder="请输入称号图片" allow-clear/>
                              </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24">
                              <a-form-item label="达成条件" prop="conditionId">
                                <a-input v-model="queryParam.conditionId" placeholder="请输入达成条件" allow-clear/>
                              </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24">
                              <a-form-item label="slogan" prop="slogan">
                                <a-input v-model="queryParam.slogan" placeholder="请输入slogan" allow-clear/>
                              </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24">
                              <a-form-item label="升1级所需的组局数量" prop="groupNum">
                                <a-input v-model="queryParam.groupNum" placeholder="请输入升1级所需的组局数量" allow-clear/>
                              </a-form-item>
                            </a-col>-->


            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['config:appellation:add']">
          <a-icon type="plus"/>
          新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"
                  v-hasPermi="['config:appellation:edit']">
          <a-icon type="edit"/>
          修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['config:appellation:remove']">
          <a-icon type="delete"/>
          删除
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['config:appellation:export']">
                  <a-icon type="download"/>
                  导出
                </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.UserAppellationList.open(record.id)" v-hasPermi="['config:appellation:edit']">
            <a-icon type="user"/>发放用户
          </a>
          <a-divider type="vertical" v-hasPermi="['config:appellation:edit']"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['config:appellation:edit']">
            <a-icon type="edit"/>修改
          </a>
          <a-divider type="vertical" v-hasPermi="['config:appellation:remove']"/>
          <a @click="handleDelete(record)" v-hasPermi="['config:appellation:remove']">
            <a-icon type="delete"/>删除
          </a>
        </span><span slot="type" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.TitleTypeEnum" :value="record.type"/>
        </span>

        <template slot="img" slot-scope="text, record">
          <div>
            <img v-if="record.img" :src="record.img" style="width:60px;height:62px;"/>
          </div>
        </template>
        <span slot="isEnable" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isEnable"/>
        </span>
        <span slot="alone" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.alone"/>
        </span>
        <span slot="top" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.top"/>
        </span>
        <template slot="notLightImg" slot-scope="text, record">
          <div>
            <img v-if="record.notLightImg" :src="record.notLightImg" style="width:60px;height:62px;"/>
          </div>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <user-appellation-list ref="UserAppellationList"></user-appellation-list>
  </page-header-wrapper>
</template>

<script>
import {listAppellation, delAppellation} from '@/api/config/appellation'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import UserAppellationList from './modules/UserAppellationList.vue';


export default {
  name: 'Appellation',
  components: {
    CreateForm,
    CustomDictTag,
    UserAppellationList
},
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: null,
        actType: null,
        name: null,
        sort: null,
        level: null,
        img: null,
        notLightImg:null,
        conditionId: null,
        reach:null,
        slogan: null,
        groupNum: null,
        isEnable: null,
        alone: null,
        top: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '称号类型',
          dataIndex: 'type',
          ellipsis: true,
          scopedSlots: {customRender: 'type'},
          align: 'center'
        },
        /*{
          title: '活动称号类型 1:普通称号,2:星河称号',
          dataIndex: 'actType',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '称号名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '级别',
          dataIndex: 'level',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '称号图片',
          dataIndex: 'img',
          ellipsis: true,
          scopedSlots: {customRender: 'img'},
          align: 'center'
        },
        {
          title: '未点亮图片',
          dataIndex: 'notLightImg',
          ellipsis: true,
          scopedSlots: {customRender: 'notLightImg'},
          align: 'center'
        },
        /*{
          title: '达成条件',
          dataIndex: 'conditionId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '标语',
          dataIndex: 'slogan',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '达成条件描述',
          dataIndex: 'reach',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '升1级所需的组局数量',
          dataIndex: 'groupNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否绝版',
          dataIndex: 'isEnable',
          scopedSlots: {customRender: 'isEnable'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否顶级',
          dataIndex: 'top',
          scopedSlots: {customRender: 'top'},
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '是否单独称号',
        //   dataIndex: 'alone',
        //   scopedSlots: {customRender: 'alone'},
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: {customRender: 'createTime'},
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '手动发放的用户',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    /** 查询称号配置列表 */
    getList() {
      this.loading = true
      listAppellation(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        type: undefined,
        actType: undefined,
        name: undefined,
        level: undefined,
        img: undefined,
        sort: undefined,
        conditionId: undefined,
        reach:undefined,
        slogan: undefined,
        groupNum: undefined,
        isEnable: undefined,
        alone: undefined,
        top: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleInfo: function (id) {
      this.$refs.IndexScriptModal.open();
      // this.$refs.IndexScriptModal.title = "剧本详情";
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delAppellation(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('config/appellation/export', {
            ...that.queryParam
          }, `appellation_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
