<template>
  <a-modal v-model:visible="visible" title="发放称号" width="50%" v-highlight :footer="null">
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="昵称">
                <a-input v-model="queryParam.userNickname" placeholder="请输入昵称" allow-clear />
              </a-form-item>

            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="获得方式">
                <a-select v-model="queryParam.isManual" placeholder="请选择获得方式" allowClear>
                  <a-select-option :value="0">
                    自动获得
                  </a-select-option>
                  <a-select-option :value="1">
                    手动发送
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="是否获得">
                <a-select v-model="queryParam.isHas" placeholder="请选择是否获得" allowClear>
                  <a-select-option :value="0">
                    未获得
                  </a-select-option>
                  <a-select-option :value="1">
                    已获得
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button :disabled="multiple" @click="send()">
          发放
        </a-button>
        <a-button :disabled="multiple" @click="revoke()">
          回收
        </a-button>
        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns"
          :refresh-loading="loading" @refresh="getList" />
      </div>

      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
        :bordered="tableBordered">

        <!-- 用户头像 -->
        <template slot="userAvatar" slot-scope="text, record">
          <div>
            <img v-if="record.userAvatar" :src="record.userAvatar" style="width:60px;height:62px;" />
          </div>
        </template>

        <!-- 获得方式 -->
        <span slot="isManual" slot-scope="text, record">
          <template v-if="record.isHas">
            <a-tag v-if="record.isManual == 1" color="cyan">
              手动发送
            </a-tag>
            <a-tag v-if="record.isManual == 0" color="blue">
              自动获得
            </a-tag>
          </template>
          <a-tag v-else>
            未获得
          </a-tag>
        </span>

        <!-- 是否获得 -->
        <span slot="isHas" slot-scope="text, record">
          <a-tag v-if="record.isHas" color="green">
            已获得
          </a-tag>
          <a-tag v-else>
            未获得
          </a-tag>
        </span>

        <!-- 获得时间 -->
        <span slot="appellationCreateTime" slot-scope="text, record">
          <template v-if="record.isHas">
            {{ parseTime(record.appellationCreateTime) }}
          </template>
          <a-tag v-else>
            未获得
          </a-tag>
        </span>

        <span slot="operation" slot-scope="text, record">
          <a v-if="!record.isHas" @click="send(record)">
            发放
          </a>
          <a v-if="record.isHas && record.isManual == 1" @click="revoke(record)">
            回收
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </a-modal>
</template>

<script>
import { userAppellation, send, revoke } from '@/api/config/appellation'
import { mapGetters } from 'vuex'
import { tableMixin } from '@/store/table-mixin'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'IndexUserModal',
  components: {
    CustomDictTag,
  },
  mixins: [tableMixin],
  data() {
    return {
      visible: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      loading: false,
      total: 0,
      // 查询参数
      queryParam: this.getDefaultQueryParams(null),
      columns: [
        {
          title: '头像',
          dataIndex: 'userAvatar',
          scopedSlots: { customRender: 'userAvatar' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'userNickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否获得',
          dataIndex: 'isHas',
          scopedSlots: { customRender: 'isHas' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '获得方式',
          dataIndex: 'isManual',
          scopedSlots: { customRender: 'isManual' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '获得时间',
          dataIndex: 'appellationCreateTime',
          scopedSlots: { customRender: 'appellationCreateTime' },
          ellipsis: false,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询用户信息列表 */
    getList() {
      this.loading = true;
      this.selectedRows = [];
      this.selectedRowKeys = [];
      userAppellation(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = this.getDefaultQueryParams(this.queryParam.appellationId);
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    open(appellationId) {
      this.visible = true;
      this.queryParam.appellationId = appellationId
      this.getList();
    },
    // 获取默认查询参数
    getDefaultQueryParams(appellationId) {
      return {
        userNickName: null,
        appellationId: appellationId,
        isHas: undefined,
        isManual: undefined,
        pageNum: 1,
        pageSize: 10
      };
    },
    // 发放称号
    send(record) {
      const userIds = record ? [record.userId] : this.selectedRows.map(rowData => rowData.userId);
      send({ userIds: userIds, appellationId: this.queryParam.appellationId }).then(r => {
        this.$message.success('发放成功');
        this.getList();
      });
    },
    // 回收称号
    revoke(record) {
      const userIds = record ? [record.userId] : this.selectedRows.map(rowData => rowData.userId);
      revoke({ userIds: userIds, appellationId: this.queryParam.appellationId }).then(r => {
        this.$message.success('回收成功');
        this.getList();
      });
    }
  }
}
</script>